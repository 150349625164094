<template>
  <v-dialog v-model="dialog" width="600" @click:outside="close">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="secondary" rounded small>
        {{ $t("common.details") }}
      </v-btn>
    </template>

    <v-card class="pt-5">
      <close-button
        style="position: absolute; top: 10px; right: 10px"
        @click="close"
      />

      <v-card-text>
        <!-- Avatar -->
        <div class="text-center">
          <v-avatar class="avatar" size="120">
            <v-img
              :src="$utils.userImage(user, '120x120')"
              width="120"
              height="120"
              :alt="user.username"
            />
          </v-avatar>

          <div class="font-weight-bold mt-5" style="font-size: 24px">
            {{ user.username }}
          </div>
        </div>

        <!-- Buttons -->
        <div class="d-flex justify-center my-5" style="gap: 20px">
          <!-- Ban -->
          <v-btn
            color="secondary"
            rounded
            :disabled="isAdmin"
            :loading="$loading('admin/banUser') === user.id"
            @click="banUser"
          >
            {{ user.is_active ? $t("users.ban") : $t("users.unban") }}
          </v-btn>

          <!-- Delete -->
          <v-btn
            color="secondary"
            rounded
            :disabled="isAdmin"
            :loading="$loading('users/delete') === user.id"
            @click="deleteUser"
          >
            {{ $t("users.delete") }}
          </v-btn>

          <UserRoleSelect :user="user" />
        </div>

        <div v-if="$loading('users/fetchStats')" class="mt-10">
          <spinner medium center />
        </div>

        <!-- Stats -->
        <div v-else class="fields">
          <div
            v-for="field in fields"
            :key="field.label"
            class="field d-flex py-2"
            :class="{ indent: !!field.indent }"
          >
            <label class="text--secondary">{{ field.label }}:</label>
            <span class="font-weight-medium pl-3">{{ field.value }}</span>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import UserRoleSelect from "./UserRoleSelect.vue";

export default {
  components: { UserRoleSelect },

  props: {
    user: Object,
  },

  data: () => ({
    data: {
      administrated_groups: 0,
      followed_users_number: 0,
      followers_number: 0,
      joined_groups: 0,
      number_of_feed_posts: 0,
      number_of_group_posts: 0,
      number_of_logins: 0,
      number_of_posts: 0,
    },
    dialog: false,
  }),

  computed: {
    fields() {
      return [
        {
          label: this.$t("dashboard.numberOfLogins"),
          value: this.data.number_of_logins,
        },
        {
          label: this.$t("dashboard.numberOfPosts"),
          value: this.data.number_of_posts,
        },
        {
          label: this.$t("dashboard.postsInFeed"),
          value: this.data.number_of_feed_posts,
          indent: true,
        },
        {
          label: this.$t("dashboard.postsInGroup"),
          value: this.data.number_of_group_posts,
          indent: true,
        },
        {
          label: this.$t("dashboard.joinedGroups"),
          value: this.data.joined_groups,
        },
        {
          label: this.$t("dashboard.administratedGroups"),
          value: this.data.administrated_groups,
          indent: true,
        },
        {
          label: this.$t("users.followedUsers"),
          value: this.data.followed_users_number,
        },
        {
          label: this.$t("users.followers"),
          value: this.data.followers_number,
        },
      ];
    },

    isAdmin() {
      return this.$utils.roleCheck(
        ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
        this.user
      );
    },
  },

  watch: {
    dialog: "fetchData",
  },

  methods: {
    fetchData() {
      if (!this.dialog) {
        return;
      }

      this.$store
        .dispatch("users/fetchStats", this.user.id)
        .then((data) => {
          this.data = data;
        })
        .catch(() => {
          this.$toast.error("Error while fetching user stats.");
        });
    },

    banUser() {
      this.$store
        .dispatch("admin/banUser", this.user.id)
        .then((resp) => {
          this.$set(this.user, "is_active", !this.user.is_active);
          this.$toast.success(resp.message);
        })
        .catch(() => this.$toast.error("Error while banning user."));
    },

    async deleteUser() {
      if (!(await this.$confirm())) {
        return;
      }

      this.$store
        .dispatch("users/delete", this.user.id)
        .then((resp) => {
          this.$emit("delete");
          this.$toast.success(resp.message);
          this.close();
        })
        .catch(() => this.$toast.error("Error while deleting user."));
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  &:not(.indent):not(:first-of-type) {
    border-top: 1px solid $gray;
  }

  &.indent {
    margin-left: 50px;
  }
}
</style>
