<template>
  <column-layout class="page" sticky-sides noRight>
    <!-- Left -->
    <template v-slot:left>
      <AdminMenu />
    </template>

    <!-- Main -->
    <template>
      <div class="widget">
        <label class="label">
          {{ $t("common.users") }}
        </label>

        <div class="card pa">
          <UsersStats noCharts />

          <UsersTable class="mt-10" />
        </div>
      </div>
    </template>
  </column-layout>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu.vue";
import UsersStats from "@/components/admin/dashboard/UsersStats.vue";
import UsersTable from "@/components/admin/users/UsersTable.vue";

export default {
  metaInfo: { title: "Users" },

  components: { AdminMenu, UsersStats, UsersTable },
};
</script>

<style lang="scss"></style>
