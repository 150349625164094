<template>
  <v-data-table
    :items="data"
    :headers="headers"
    :items-per-page.sync="perPage"
    :page.sync="page"
    :server-items-length="total"
    :loading="$loading('users/fetch')"
    :footer-props="$config.vuetify.vDataTable.footerProps"
    @update:page="fetchData"
    @update:items-per-page="fetchData"
  >
    <template v-slot:top>
      <div class="d-flex mb-3">
        <SearchInput
          v-model="searchInput"
          @search="
            page = 1;
            fetchData(true);
          "
          class="rounded-lg"
          style="max-width: 250px"
        />
      </div>
    </template>

    <template v-slot:item.actions="{ item }">
      <div class="d-flex">
        <v-btn
          class="mr-3"
          color="secondary"
          small
          rounded
          target="_blank"
          :to="{ name: 'app.user', params: { id: item.id } }"
        >
          {{ $t("users.profile") }}
        </v-btn>

        <UserDetailsModal :user="item" @delete="fetchData" />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import SearchInput from "@/components/common/SearchInput.vue";
import UserDetailsModal from "./UserDetailsModal.vue";

export default {
  components: { SearchInput, UserDetailsModal },

  data() {
    return {
      searchInput: "",
      data: [],
      page: 1,
      perPage: 10,
      total: null,
      headers: [
        {
          text: this.$t("common.username"),
          sortable: false,
          value: "username",
        },
        {
          sortable: false,
          value: "actions",
          width: "1%",
        },
      ],
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      const params = {
        "exact_search[all]": 1,
        page: this.page,
        per_page: this.perPage,
      };

      if (this.searchInput) {
        params["search[username]"] = this.searchInput;
      }

      this.$store
        .dispatch("users/fetch", params)
        .then((res) => {
          this.data = res.data;
          this.total = res.meta.total;
        })
        .catch(() => {
          this.$toast.error("Error while fetching users.");
        });
    },
  },
};
</script>

<style></style>
