<template>
  <v-select
    v-if="$user.isSuperAdmin()"
    v-model="model"
    :items="items"
    :loading="
      $loading('admin/setAdminRole') === user.id ||
      $loading('admin/removeAdminRole') === user.id
    "
    hide-details
    rounded
    height="44"
    color="secondary"
    background-color="secondary"
    style="max-width: 160px"
    class="mt-0 pt-0"
    menu-props="offsetY"
  />
</template>

<script>
export default {
  props: {
    user: Object,
  },

  computed: {
    items() {
      return [
        { text: this.$t("common.user"), value: "user" },
        { text: this.$t("common.admin"), value: "admin" },
      ];
    },

    model: {
      get() {
        console.log(
          this.user,
          this.$utils.roleCheck(["ROLE_ADMIN"], this.user)
        );
        return this.$utils.roleCheck(["ROLE_ADMIN"], this.user)
          ? "admin"
          : "user";
      },
      set(val) {
        val === "admin" && this.setAdmin();
        val === "user" && this.removeAdmin();
      },
    },
  },

  methods: {
    setAdmin() {
      this.$store
        .dispatch("admin/setAdminRole", this.user.id)
        .then((user) => {
          this.$set(this.user, "roles", user.roles);
          this.$toast.success(this.$t("common.success"));
        })
        .catch(() => {
          this.$toast.error("Error while setting admin role.");
        });
    },

    removeAdmin() {
      this.$store
        .dispatch("users/removeAdminRole", this.user.id)
        .then((user) => {
          this.$set(this.user, "roles", user.roles);
          this.$toast.success(this.$t("common.success"));
        })
        .catch(() => {
          this.$toast.error("Error while removing admin role.");
        });
    },
  },
};
</script>

<style></style>
